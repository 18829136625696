<template>
  <CsTableCard
    :loading="loading"
    :rows="rows"
    :columns="columns"
    card-header="Elenco Utenti"
  >
    <template slot="topLeft">
      <aggiungi
        v-if="canUpdate"
        :utenti="rows"
        @onAdd="addRow"
      />
    </template>

    <template
      slot="table-row"
      slot-scope="props"
    >
      <cs-table-action-column-default
        :show-actions="canUpdate"
        :props="props"
      >
        <cs-table-action-item-delete
          :show="canUpdate"
          :row="props.row"
          @click="removeRow"
        />
      </cs-table-action-column-default>

    </template>

  </CsTableCard>
</template>

<script>

import Aggiungi from '@/views/gruppi/Utenti_Aggiungi.vue'

export default {
  components: { Aggiungi },
  data() {
    return {
      loading: true,
      columns: [
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      canUpdate: this.$grants.GRUPPI_AGGIORNA.can,
    }
  },
  created() {
    if (!this.$grants.GRUPPI_VISUALIZZA.can) this.$routing.ERRORE401.push()
    this.$remote.gruppi.single(this.$route.params.id)
      .then(res => {
        this.setBreadcrumbData(this.$store, this.$route.name, { nome: res.data.nome })
        this.rows = res.data.utenti
        this.loading = false
      })
      .catch(err => {
        this.showReposonseMessage(err)
        this.$routing.ERRORE404.push()
      })
  },
  methods: {
    addRow(params) {
      this.addRowAtStart(this.rows, params)
      this.showAddSuccessMessage()
    },
    removeRow(params) {
      this.showDeleteSwal().then(res => {
        if (!res.value) return

        this.loading = true
        this.$remote.gruppi.utente_remove(this.$route.params.id, params.id)
          .then(() => {
            this.deleteRow(this.rows, params.originalIndex)
            this.showDeleteSuccessMessage()
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
  },
}
</script>
